import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

const assetVersion = "?v=2201241623"

const ShopsIndexPage = () => (
  <Layout sectionClasses={"t-shop"} dataNamespace={"shop"}>
    <SEO title="SHOP" />
    <h2 className="t-shop__ttl p-title">
      <span className="t-shop__ttl__main">SHOP</span>
    </h2>
    <div className="t-shop__detail">
      <div className="t-shop__detail__close">
        <p className="t-shop__detail__close__ttl">お客様各位</p>
        <p className="t-shop__detail__close__txt">
          閉店のお知らせ<br />
          勝手ながら、こちらの店舗は2022年7月28日をもって閉店致しました。<br />
          長い間ご愛顧いただき誠にありがとうございます。
        </p>
      </div>
      <ul className={"t-shop__detail__list"}>
        <li>
          <img className="" src={"/images/stores/yurakucho-marui.jpg"} alt="" />
          <h3 className={"t-shop__detail__list__ttl"}>
            HOTARU PERSONALIZED <span>有楽町マルイ店</span>
          </h3>
          <p className={"t-shop__detail__list__address"}>
            東京都千代田区有楽町2-7-1 <span>有楽町マルイ 1F</span>
          </p>
          {/* <div className={"t-shop__detail__list__btn"}>
            <Link className={"button"} to="/store/yurakucho">
              詳しく見る<span>→</span>
            </Link>
          </div> */}
        </li>
      </ul>
    </div>
  </Layout>
)

export default ShopsIndexPage
